<template>
	<FormLista
		v-if="listaEdit"
		:listaCarregada="listaEdit"
		tituloBtn="Save"
		@salvarLista="enviarLista($event)"
	/>
</template>

<script>
	import firebase from 'firebase';
	import FormLista from '@/components/elementos/FormLista.vue';
	export default {
		name: 'EditarLista',
		components: {
			FormLista,
		},
		data() {
			return {
				listaEdit: null,
			};
		},
		computed: {
			listas() {
				return this.$store.state.user.listas;
			},
		},
		methods: {
			carregarLista() {
				const listas = this.$store.state.user.listas;
				const key = this.$route.params.key;
				const lista = listas[key];
				if (!lista) {
					return this.$router.push('/');
				}
				this.listaEdit = lista;
			},
			enviarLista(data) {
				this.$store.commit('SET_ADICIONAR_LOADING');
				firebase
					.database()
					.ref('users')
					.child(firebase.auth().currentUser.uid)
					.child(this.$route.params.key)
					.update(data)
					.then(() => {
						this.$router.push('/');
						this.$store.commit('user/SET_ATUALIZAR_LISTA', true);
						return this.$store.commit('SET_ALERTA', {
							ativado: true,
							titulo: ';)',
							mensagem: 'Your list has been updated',
						});
					})
					.catch((err) => {
						return this.$store.commit('SET_ALERTA', {
							ativado: true,
							titulo: 'Error',
							mensagem: err.message,
						});
					})
					.finally(() => this.$store.commit('SET_REMOVER_LOADING'));
			},
		},
		mounted() {
			if (this.$store.state.user.listas) {
				this.carregarLista();
			}
		},
		watch: {
			listas() {
				this.carregarLista();
			},
		},
	};
</script>
